// 移动端首页
<template>
  <div class="m_total" id="mtotal">
    <!-- 遮罩层 -->
    <div id="coverm"></div>
    <!-- 置顶top -->
    <Mtop ref="mtop"></Mtop>
    <div v-if="$store.state.islogin">
      <!-- 正常展示 -->
      <div v-show="mcendg.center">
        <div class="mcen">
          <transition name="mcenter_1">
            <div class="mcen_con" v-show="enter">
              <div class="mcen_con-top">
                <img
                  :src="user.avatar ? user.avatar : ''"
                  :onerror="
                    ub_index == 2
                      ? $store.state.defaulava_bo
                      : ub_index == 3
                      ? $store.state.defaulava_u
                      : $store.state.defaulava
                  "
                  alt="用户头像图标"
                />
                <div class="mcen_con-top-right">
                  <div class="mcen_con-top-right-t">
                    <a>
                      {{
                        $store.state.user.nickname
                          ? $store.state.user.nickname
                          : $store.state.user.username
                          ? $store.state.user.username
                          : $store.state.user.email
                          ? $store.state.user.email
                          : '注册/登录'
                      }}
                    </a>
                    <!-- mcen_vip_b是会员的图标，mcen_vip_c是非会员的图标 -->
                    <div class="mcen_vip_a mcen_vip_b"></div>
                  </div>
                  <div class="mcen_con-top-right-b">
                    <span>目前您的身份为会员</span>
                  </div>
                </div>
                <div class="mcen_con-top-right-g">
                  <span>999</span>
                </div>
              </div>
              <div class="mcen_con-bottom">
                <!-- <div class="mcen_card-icon" @click="YS.routetogo({ path: '/mrecord' })">
                <img src="../../assets/img/mcen1@2x.png" />
              </div>
              <div class="mcen_card-icon">
                <img src="../../assets/img/mcen2@2x.png" />
              </div>
              <div class="mcen_card-icon" @click="YS.routetogo({ path: '/mvip' })">
                <img src="../../assets/img/mcen3@2x.png" />
              </div> -->
                <div
                  class="mcen_card-icon"
                  @click="YS.routetogo({ path: '/mrecord', query: { isrec: false } })"
                >
                  <img src="../../assets/img/mcen1@2x.png" alt="历史记录图标" />
                  <a>历史记录</a>
                </div>
                <div
                  class="mcen_card-icon"
                  @click="YS.routetogo({ path: '/mrecord', query: { isrec: true } })"
                >
                  <img src="../../assets/img/mcen2@2x.png" alt="收藏夹图标" />
                  <a>收藏夹</a>
                </div>
                <div class="mcen_card-icon" @click="seekaPiece">
                  <img src="../../assets/img/mcen3@2x.png" alt="求片图标" />
                  <a>求片</a>
                </div>
                <div class="mcen_card-icon" @click="cliHuiyuan">
                  <img src="../../assets/img/mcen4@2x.png" alt="充值图标" />
                  <a>会员</a>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <transition name="mcenter_2">
          <div class="mcen_list" v-show="enter">
            <div class="mcen_list_box" @click="clihis">
              <div class="mcen_list_box-icon mcen_list_box-1"></div>
              <a>播放记录</a>
              <img class="mcen_list-right" src="../../assets/img/right3.png" alt="前往播放记录" />
            </div>
            <!-- <div class="mcen_list_box" @click="clipayhis">
            <div class="mcen_list_box-icon mcen_list_box-2"></div>
            <a>充值记录</a>
            <img class="mcen_list-right" src="../../assets/img/right3.png" alt="前往充值记录" />
          </div> -->
            <div class="mcen_list_box" @click="cliusercent">
              <div class="mcen_list_box-icon mcen_list_box-3"></div>
              <a>账户信息</a>
              <img class="mcen_list-right" src="../../assets/img/right3.png" alt="前往账户信息" />
            </div>
            <div class="mcen_list_box" @click="todialog('safe')">
              <div class="mcen_list_box-icon mcen_list_box-4"></div>
              <a>安全设置</a>
              <img class="mcen_list-right" src="../../assets/img/right3.png" alt="前往安全设置" />
            </div>
            <div class="mcen_list_box" @click="clifeedback">
              <div class="mcen_list_box-icon mcen_list_box-6"></div>
              <a>意见反馈</a>
              <img class="mcen_list-right" src="../../assets/img/right3.png" alt="前往意见反馈" />
            </div>
            <div class="mcen_list_box" @click="outloginbefore">
              <div class="mcen_list_box-icon mcen_list_box-5"></div>
              <a>退出登录</a>
              <img class="mcen_list-right" src="../../assets/img/right3.png" alt="退出登录" />
            </div>
          </div>
        </transition>
      </div>
      <!-- 充值记录 -->
      <transition name="mcenter_2">
        <div v-show="mcendg.rec">
          <div class="manquan">
            <img src="../../assets/img/left2.png" @click="todialog('center')" alt="充值记录图标" />
            <a>充值记录</a>
          </div>
          <div class="manquan_body">
            <div class="mcen_list_box2" v-for="(item, index) in recharge_record_list" :key="index">
              <!-- <div class="mcen_list_box-icon mcen_list_box-1"></div> -->
              <a>{{ YS.toTime(item.pay_time) }}</a>
              <a>{{ item.description }}&nbsp;{{ item.amount }}&nbsp;{{ item.currency }}</a>
            </div>
            <!-- 暂无记录 -->
            <div class="record_none" v-show="!recharge_record_list">
              <img src="../../assets/img/mnone@2x.png" alt="没有充值记录图片" />
              <span>暂无记录</span>
            </div>
          </div>
        </div>
      </transition>
      <!-- 账户信息 -->
      <transition name="mcenter_2">
        <div v-show="mcendg.acc">
          <div class="manquan">
            <img src="../../assets/img/left2.png" @click="todialog('center')" alt="前往账户信息" />
            <a>账户信息</a>
          </div>
          <div class="manquan_body">
            <div class="mcen_list_box2" @click="todialog('changenickname')">
              <!-- <div class="mcen_list_box-icon mcen_list_box-1"></div> -->
              <a>昵称</a>
              <a>{{ user.nickname }}</a>
              <img src="../../assets/img/right3.png" alt="前往修改昵称" />
            </div>
            <div class="mcen_list_box2" @click="!user.email && todialog('bindmail')">
              <!-- <div class="mcen_list_box-icon mcen_list_box-1"></div> -->
              <a>电子邮箱</a>
              <a>{{ user.email }}</a>
              <img v-if="!user.email" src="../../assets/img/right3.png" alt="前往绑定邮箱" />
            </div>
          </div>
        </div>
      </transition>
      <!-- 安全设置 -->
      <transition name="mcenter_2">
        <div v-show="mcendg.safe">
          <div class="manquan">
            <img src="../../assets/img/left2.png" @click="todialog('center')" alt="安全设置图标" />
            <a>安全设置</a>
          </div>
          <div class="manquan_body">
            <!-- <div
            class="mcen_list_box"
            @click="
              todialog('bindphone');
              resInput();
            "
          >
            <a>绑定手机</a>
            <img class="mcen_list-right" src="../../assets/img/right3.png" alt="前往修改密码" />
          </div> -->
            <div
              class="mcen_list_box"
              @click="
                todialog('bindmail');
                resInput();
              "
            >
              <!-- <div class="mcen_list_box-icon mcen_list_box-1"></div> -->
              <a>绑定邮箱</a>
              <img class="mcen_list-right" src="../../assets/img/right3.png" alt="前往修改密码" />
            </div>
            <div
              class="mcen_list_box"
              @click="
                todialog('changepow');
                resInput();
              "
            >
              <!-- <div class="mcen_list_box-icon mcen_list_box-1"></div> -->
              <a>修改密码</a>
              <img class="mcen_list-right" src="../../assets/img/right3.png" alt="前往修改密码" />
            </div>
            <div
              class="mcen_list_box"
              @click="
                todialog('wuye');
                resInput();
              "
            >
              <a>午夜版密码</a>
              <img
                class="mcen_list-right"
                @click="todialog('wuye')"
                src="../../assets/img/right3.png"
                alt="前往修改密码"
              />
            </div>
            <!-- <div class="mcen_list_box" @click="todialog('changeemailpow')">
            <a>通过邮箱修改登录密码</a>
            <img class="mcen_list-right" src="../../assets/img/right3.png" />
          </div> -->
          </div>
        </div>
      </transition>
      <!-- 意见反馈 -->
      <transition name="mcenter_2">
        <div v-show="mcendg.feedback">
          <div class="manquan">
            <img src="../../assets/img/left2.png" @click="todialog('center')" alt="意见反馈图标" />
            <a>意见反馈</a>
          </div>
          <div class="manquan_body">
            <div class="mcen_list_box mcen_feedbook_1" @click="downFeedbook">
              <!-- <div class="mcen_list_box-icon mcen_list_box-1"></div> -->
              <span class="feedbook_first">问题分类</span>
              <div class="feedbook_2">
                <span>{{ catename }}</span>
                <img
                  class="mcen_list-right feedbookimg"
                  src="../../assets/img/right3.png"
                  alt="下拉开关问题反馈菜单"
                />
              </div>
              <!-- 意见下拉 -->
              <transition name="mcenter_7">
                <template v-if="feedback_category_list">
                  <div class="feedbook_down" v-show="feedback_isok" @click="cliFeedbookList">
                    <span
                      v-for="(item, index) in feedback_category_list"
                      :key="index"
                      :data-id="item.id"
                    >
                      {{ item.name }}
                    </span>
                  </div>
                </template>
              </transition>
            </div>
            <!-- 问题内容输入 -->
            <div class="mcen_feedbook_11">
              <span>请描述您的问题</span>
              <textarea maxlength="140" v-model="opi.content" placeholder="请输入内容"></textarea>
            </div>
            <div class="mcen_list_box mcen_feedbook_111">
              <span>联系方式</span>
              <input v-model="opi.contactdetails" placeholder="QQ/微信号/电子邮箱（非必填）" />
            </div>
          </div>
          <div class="bottomclibox">
            <span class="bottomcli" @click="clia" :class="{ cliloading: loadCli }">
              <span v-show="!loadCli">提交反馈</span>
              <div class="dots-6" v-show="loadCli"></div>
            </span>
          </div>
        </div>
      </transition>
      <!-- 修改密码 -->
      <transition name="mcenter_2">
        <div v-show="mcendg.changepow">
          <div class="manquan">
            <img src="../../assets/img/left2.png" @click="todialog('safe')" alt="返回安全设置" />
            <a>修改密码</a>
          </div>
          <div class="manquan_body">
            <div class="mcen_list_box">
              <input v-model="oldpass" placeholder="输入旧密码" />
            </div>
            <div class="mcen_list_box">
              <input v-model="newpass" type="password" placeholder="输入新密码" />
            </div>
            <div class="mcen_list_box">
              <input v-model="respass" type="password" placeholder="重复新密码" />
            </div>
            <span class="errorpas">{{ errorpass }}</span>
            <!-- <a class="mcen_list_yes" @click="respow">确认修改</a> -->
          </div>
          <div class="bottomclibox">
            <span class="bottomcli" @click="respow" :class="{ cliloading: loadCli }">
              <span v-show="!loadCli">确认修改</span>
              <div class="dots-6" v-show="loadCli"></div>
            </span>
          </div>
        </div>
      </transition>
      <!-- 修改午夜版密码 -->
      <transition name="mcenter_2">
        <div v-show="mcendg.wuye">
          <div class="manquan">
            <img src="../../assets/img/left2.png" @click="todialog('safe')" alt="返回安全设置" />
            <a>修改午夜版密码</a>
          </div>
          <div class="manquan_body">
            <div class="mcen_list_box">
              <input v-model="oldpass" placeholder="输入旧密码，初始密码为1818" />
            </div>
            <div class="mcen_list_box">
              <input v-model="newpass" type="password" placeholder="输入新密码" />
            </div>
            <div class="mcen_list_box">
              <input v-model="respass" type="password" placeholder="重复新密码" />
            </div>
            <span class="errorpas">{{ errorpass }}</span>
            <!-- <a class="mcen_list_yes" @click="respow">确认修改</a> -->
          </div>
          <div class="bottomclibox">
            <span class="bottomcli" @click="resHidden" :class="{ cliloading: loadCli }">
              <span v-show="!loadCli">确认修改</span>
              <div class="dots-6" v-show="loadCli"></div>
            </span>
          </div>
        </div>
      </transition>
      <!-- 通过邮箱修改密码 -->
      <transition name="mcenter_2">
        <div v-show="mcendg.changeemailpow">
          <div class="manquan">
            <img src="../../assets/img/left2.png" @click="todialog('safe')" alt="返回安全设置" />
            <a class="manquan_a">修改密码</a>
          </div>
          <div class="manquan_body">
            <div class="mcen_list_box">
              <input v-model="cemail.email" placeholder="输入邮箱" />
            </div>
            <div class="mcen_list_box">
              <input v-model="cemail.new_password" type="password" placeholder="输入新密码" />
            </div>
            <!-- <div class="mcen_list_box">
          <input v-model="cemail.captcha" placeholder="" />
          </div> -->
            <div class="mcen_list_box">
              <input
                class="lt-input mcodeinput"
                maxlength="6"
                v-model="cemail.captcha"
                placeholder="请输入验证码"
              />
              <a id="codea" class="varcode" :class="{ cliloading: varCli }" @click="getmailver">
                {{ $store.state.code.count }}
              </a>
            </div>
            <!-- <a class="mcen_list_yes" @click="cliresmail">确认修改</a> -->
          </div>
          <div class="bottomclibox">
            <span class="bottomcli" @click="cliresmail">确认修改</span>
          </div>
        </div>
      </transition>
      <!-- 修改用户名 -->
      <transition name="mcenter_2">
        <div v-show="mcendg.changenickname">
          <div class="manquan">
            <img src="../../assets/img/left2.png" @click="todialog('acc')" alt="返回账户信息" />
            <a>修改昵称</a>
          </div>
          <div class="manquan_body">
            <div class="mcen_list_box">
              <input v-model="resnickname_input" placeholder="输入昵称" />
            </div>
            <!-- <a class="mcen_list_yes" @click="resnickname">确认修改</a> -->
          </div>
          <div class="bottomclibox">
            <span class="bottomcli" @click="resnickname" :class="{ cliloading: loadCli }">
              <span v-show="!loadCli">确认修改</span>
              <div class="dots-6" v-show="loadCli"></div>
            </span>
          </div>
        </div>
      </transition>
      <!-- 绑定邮箱 -->
      <transition name="mcenter_2">
        <div v-show="mcendg.bindmail">
          <div class="manquan">
            <img src="../../assets/img/left2.png" @click="todialog('safe')" alt="返回安全设置" />
            <a class="manquan_a">绑定邮箱</a>
          </div>
          <div class="manquan_body">
            <div class="mcen_list_box">
              <input v-model="resmail.email" placeholder="输入邮箱" />
            </div>
            <div class="mcen_list_box">
              <input
                class="lt-input mcodeinput"
                maxlength="6"
                v-model="resmail.captcha"
                placeholder="请输入验证码"
              />
              <a id="codea" class="varcode" :class="{ cliloading: varCli }" @click="getvarcode">
                {{ $store.state.code.count }}
              </a>
            </div>
            <!-- <a class="mcen_list_yes" >确认修改</a> -->
          </div>
          <div class="bottomclibox">
            <span class="bottomcli" @click="clibindmail" :class="{ cliloading: loadCli }">
              <span v-show="!loadCli">确认绑定</span>
              <div class="dots-6" v-show="loadCli"></div>
            </span>
          </div>
        </div>
      </transition>
      <!-- 绑定手机 -->
      <transition name="mcenter_2">
        <div v-show="mcendg.bindphone">
          <div class="manquan">
            <img src="../../assets/img/left2.png" @click="todialog('safe')" alt="返回安全设置" />
            <a class="manquan_a">绑定手机</a>
          </div>
          <div class="manquan_body">
            <div class="lt-input-phoneSgin" :class="{ countryIOS: isIOS() }">
              <VueCountryIntl
                class="country_act"
                type="phone"
                onlyValue
                searchAble
                showAreaCode
                useChinese
                iosMobileReadonly
                @onChange="country"
                v-model="schemaInput.default"
                :iso2="schemaInput.selectedObjDefault.iso2"
                schema="input"
              ></VueCountryIntl>
              <input class="phoneSgin_input" placeholder="请输入手机号" />
            </div>
            <div class="mcen_list_box">
              <input
                class="lt-input mcodeinput"
                maxlength="6"
                v-model="resmail.captcha"
                placeholder="请输入验证码"
              />
              <a id="codea" class="varcode" :class="{ cliloading: varCli }" @click="getvarcode">
                {{ $store.state.code.count }}
              </a>
            </div>
            <!-- <a class="mcen_list_yes" >确认修改</a> -->
          </div>
          <div class="bottomclibox">
            <span class="bottomcli" @click="clibindmail" :class="{ cliloading: loadCli }">
              <span v-show="!loadCli">确认绑定</span>
              <div class="dots-6" v-show="loadCli"></div>
            </span>
          </div>
        </div>
      </transition>
      <!-- WIN 公用弹窗成功 -->
      <transition name="mcenter_8">
        <div class="mpayresult1" v-show="publicDialogshow">
          <!-- <div class="mpayresult1" v-show="true"> -->
          <div class="payrbody11">
            <img src="../../assets/img/pass_ok.png" alt="成功弹窗的主要图片" />
            <span>{{ publicDialog.title }}</span>
          </div>
          <div class="payrbody12">
            <span @click="mpubyes">确认</span>
          </div>
          <img
            class="login-close"
            @click="openandclo('publicDialogshow', false)"
            src="../../assets/img/close.png"
            alt="关闭成功弹窗弹窗"
          />
        </div>
      </transition>

      <!-- WIN 公用失败 -->
      <transition name="mcenter_8">
        <div class="mpayresult2" v-show="publicDialogshowError">
          <!-- <div class="mpayresult2" v-show="true"> -->
          <div class="payrbody3">
            <a class="mvip_fail">{{ publicDialogError.title }}</a>
            <img class="marb" src="../../assets/img/mvip_fail.png" alt="失败提示弹窗的主要图片" />
          </div>
          <div class="payafter">
            <!-- <span>取消</span> -->
            <span @click="pubErrorbutton">返回重试</span>
          </div>

          <img
            class="login-close"
            @click="openandclo('publicDialogshowError', false)"
            src="../../assets/img/close.png"
            alt="关闭失败提示弹窗"
          />
        </div>
      </transition>

      <!-- WIN 加载获取 -->
      <transition name="mcenter_8">
        <div class="mpayresult1" v-show="loadwin">
          <!-- <div class="mpayresult1" v-show="true"> -->
          <div class="payrbody11">
            <span class="mpayokspan">支付成功！</span>
            <span>{{ geiviptime }}秒后获取会员状态，请勿关闭此窗口。</span>
            <img
              class="load0"
              src="../../assets/img/loading.png"
              height="100"
              width="100"
              alt="提示加载图片"
            />
          </div>
          <div class="payrbody12">
            <!-- <span @click="openandclo('publicDialogshow', false)">确认</span> -->
          </div>
          <img
            class="login-close"
            @click="openandclo('publicDialogshow', false)"
            src="../../assets/img/close.png"
            alt="关闭加载获取vip状态的弹窗"
          />
        </div>
      </transition>
      <!-- WIN 加载获取 - 非首次进入 -->
      <transition name="mcenter_8">
        <div class="mpayresult1" v-show="loadwinSecond">
          <!-- <div class="mpayresult1" v-show="true"> -->
          <div class="payrbody11">
            <span class="mpayokspan">正在获取订单结果</span>
            <span>{{ geiviptime }}秒后获取会员状态，请勿关闭此窗口。</span>
            <img
              class="load0"
              src="../../assets/img/loading.png"
              height="100"
              width="100"
              alt="提示加载图片"
            />
          </div>
          <div class="payrbody12">
            <!-- <span @click="openandclo('publicDialogshow', false)">确认</span> -->
          </div>
          <img
            class="login-close"
            @click="openandclo('loadwinSecond', false)"
            src="../../assets/img/close.png"
            alt="关闭加载获取vip状态的弹窗"
          />
        </div>
      </transition>
      <Mbottom v-show="mbottom"></Mbottom>
      <!-- WIN 确认退出登录 -->
      <div class="mpayresult" v-show="outl">
        <div class="confirn_mess1">
          <img class="outimg" src="../../assets/img/leave_vip.png" alt="退出登录确定弹窗提示图片" />
          <span class="outspan">是否需要退出登录？</span>
        </div>
        <div class="confirn_buttons">
          <a class="outa1" @click="outlogin">确认</a>
          <a class="outa2" @click="openandclo('outl', false)">取消</a>
        </div>
        <img
          class="login-close"
          @click="openandclo('outl', false)"
          src="../../assets/img/close.png"
          alt="关闭退出登录确定弹窗"
        />
      </div>
      <!-- WIN 公用出现问题 -->
      <transition name="mcenter_8">
        <div class="mpayresult2" v-show="havePloblem">
          <!-- <div class="mpayresult2" v-show="true"> -->
          <div class="payrbody3">
            <a class="ubnot_a">{{ ubnot.title }}</a>
            <a class="ubnot_a">{{ ubnot.text }}</a>
            <img class="marb_ubnot" src="../../assets/img/ubnot.png" alt="失败提示弹窗的主要图片" />
          </div>
          <div class="payafter">
            <!-- <span>取消</span> -->
            <span @click="openandclo('havePloblem', false)">{{ ubnot.button }}</span>
          </div>

          <img
            class="login-close"
            @click="openandclo('havePloblem', false)"
            src="../../assets/img/close.png"
            alt="关闭失败提示弹窗"
          />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
let that;
let fetch;
let vuex;
import Mtop from '../../components/moblie/mTop';
import Mbottom from '../../components/moblie/mBottom';
export default {
  components: {
    Mtop,
    Mbottom,
  },
  name: 'mCenter',

  data() {
    return {
      ub_index: 1,
      varCli: false,
      schemaInput: {
        default: '+86',
        country: '',
        defaultValue: '+86',
        disableUse: '86',
        noImage: '+86',
        //noCode: '',
        event: '+86',
        onlyValue: '+86',
        disableCountry: '',
        onlyCountry: '',
        selectedObjDefault: {},
      },
      ubnot: {
        title: '您输入的密码前后不一致',
        text: '请返回修改',
        button: '重新填写密码',
      },
      havePloblem: false, //遇到了问题复用弹窗
      countryCode: 1,
      phoneCountry: '',
      loadwinSecond: false, //非首次进入的获取VIP状态弹窗
      loadCli: false, //是否开启加载
      pubErrortype: '', //失败弹窗类型
      vipOldtime: null,
      vipNewtime: null,
      geiviptime: 0,
      geivipcount: 0,
      jsonList: null, //地址栏参数
      loadwin: false, //加载弹窗
      piece: false, //求片状态开关
      publicDialogshow: false,
      publicDialog: {
        title: '', //标题
        type: '', //弹窗的类型
      },
      publicDialogshowError: false,
      publicDialogError: {
        title: '', //标题
        type: '', //弹窗的类型
      },
      mbottom: true, //底部显示
      catename: '', //意见反馈name
      feedback_isok: false, //意见反馈下拉框
      errorpass: '', //密码错误提示
      dialog_passok: false, //修改密码成功弹窗
      dialog_feed: false, //反馈成功弹窗
      outl: false, //退出登录弹窗
      resnickname_input: '', //修改昵称input
      recharge_record_list: [], //充值记录
      oldpass: '', //旧密码
      newpass: '', //新密码
      respass: '', //确认密码
      user: {}, //用户信息
      tokenval: {}, //token信息
      enter: false, //动画过渡中间人
      //意见反馈
      feedback_category_list: [],
      opi: {
        category_id: null,
        content: '',
        contactdetails: '',
      },
      cemail: {
        email: '',
        new_password: '',
        captcha: '',
        phrase_id: '',
      },
      resmail: {
        email: '',
        captcha: '',
        phrase_id: '',
      },
      //各级窗口
      mcendg: {
        center: true,
        rec: false,
        acc: false,
        safe: false,
        feedback: false,
        changepow: false,
        wuye: false,
        changeemailpow: false,
        changenickname: false,
        bindmail: false,
        bindphone: false,
      },
      pages: {
        page: 1,
        pagesize: 15,
      },
    };
  },
  computed: {
    isvip: () => {
      return that.$store.state.isvip;
    },
    islogin: () => {
      return that.$store.state.islogin;
    },
  },
  created() {
    that = this;
    fetch = this.api.user;
    vuex = this.$store.state;
    this.ub_index = window.g.ub_index;
    that;
    fetch;
    vuex;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (!that.YS.ismob()) {
      //web端地址
      window.location.href =
        window.g.ub_index == 2
          ? window.g.WEB_URL_BO
          : window.g.ub_index == 3
          ? window.g.WEB_URL_U
          : window.g.WEB_URL + '/history.html';
    }
  },
  mounted() {
    that.$store.commit('changelogin');
    that.enter = true;
    that.backgroundlong(); //加载长渐变背景样式
    that.cliuser(); //获取登录信息
    var url = decodeURIComponent(window.location.search);
    if (url) {
      that.jsonList = {};
      if (url.indexOf('?') > -1) {
        var str = url.slice(url.indexOf('?') + 1);
        var strs = str.split('&');
        for (var i = 0; i < strs.length; i++) {
          that.jsonList[strs[i].split('=')[0]] = strs[i].split('=')[1]; //如果出现乱码的话，可以用decodeURI()进行解码
        }
      }

      if (that.jsonList) {
        if (that.jsonList.go == 'rec') {
          that.getpayhis();
          that.todialog(that.jsonList.go);
        } else if (that.jsonList.go == 'pay') {
          that.clipayhis();
          that.getvipres();
          //写入进入支付成功的时间
          that.setOrder();
          that.openandclo('loadwin', true);
        } else if (that.jsonList.trade_status == 'success') {
          that.clipayhis();
          that.getvipres();
          //写入进入支付成功的时间
          that.setOrder();
          that.openandclo('loadwin', true);
        } else if (that.jsonList.go == 'paySecond') {
          that.clipayhis();
          that.getvipres();
          that.openandclo('loadwinSecond', true);
        } else if (that.jsonList.go == 'seekaPiece') {
          that.seekaPiece();
        } else if (that.jsonList.go == 'safe') {
          that.todialog('safe');
        }
      }
    }
    // if (that.$route.query.go) {
    //   if (that.$route.query.go == 'rec') {
    //     that.getpayhis();
    //     that.todialog(that.$route.query.go);
    //   } else if (that.$route.query.go == 'pay') {
    //     that.clipayhis();
    //     that.getvipres();
    //     //写入进入支付成功的时间
    //     that.setOrder();
    //     that.openandclo('loadwin', true);
    //   } else if (that.$route.query.go == 'paySecond') {
    //     that.clipayhis();
    //     that.getvipres();
    //     that.openandclo('loadwinSecond', true);
    //   } else if (that.$route.query.go == 'seekaPiece') {
    //     that.seekaPiece();
    //   } else if (that.$route.query.go == 'safe') {
    //     that.todialog('safe');
    //   }
    // }
    // let feedbackrow = localStorage.getItem('feedbackROW')
    // if(feedbackrow){
    //   that.seekaPiece()
    // }
  },
  methods: {
    cliHuiyuan() {
      that.$refs.mtop.messTop = '您已是会员';
      that.$refs.mtop.mess_show = true;
      setTimeout(() => {
        that.$refs.mtop.mess_show = false;
      }, 3000);
    },
    //判断是否IOS
    isIOS() {
      var u = navigator.userAgent;
      // debugger;
      return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    },
    //选择国家手机区号
    country(e) {
      console.log(e.dialCode);
      console.log(that.countryCode);

      if (e.dialCode == 86) {
        that.ubnot = {
          title: '本站暂不对中国大陆开放，敬请谅解',
          text: '',
          button: '重新选择',
        };
        that.openandclo('havePloblem', true);
        that.loadCli = false;
        e.dialCode = '';
        return;
      }
    },
    //重置input框的值
    resInput() {
      that.oldpass = '';
      that.newpass = '';
      that.respass = '';
      that.errorpass = '';
    },
    //点击求片
    seekaPiece() {
      that.feedbackcategory();
      that.piece = true;
      that.todialog('feedback');
    },
    //点击提交反馈按钮
    clia() {
      if (that.loadCli) return false;
      that.loadCli = true;
      if (that.opi.content.length >= 5) {
        that.feedbackpost();
      } else {
        that.usepubilDialogError('至少输入5个字', '');
        that.loadCli = false;
      }
    },
    //提交反馈
    async feedbackpost() {
      try {
        let data = await that.api.user.api_feedbackpost(that.opi);

        // that.openandclo('dialog_feed', true);
        data;
        that.usepubilDialog('反馈成功，感谢您的支持');
        that.todialog('center');
        that.opi.content = '';
      } catch (error) {
        that.usepubilDialogError(error.message, '');
      } finally {
        that.loadCli = false;
      }
    },
    //使用公共弹窗，成功
    usepubilDialog(title, type) {
      that.pubtype = type;
      that.publicDialog.title = title;
      // that.publicDialog.type = type;
      var coverm = document.querySelector('#coverm');
      coverm.style.display = 'block';
      this.$nextTick(() => {
        that.publicDialogshow = true;
      });
    },
    //使用公共弹窗，失败
    usepubilDialogError(title, type) {
      that.pubErrortype = type;
      that.publicDialogError.title = title;
      // that.publicDialog.type = type;
      that.publicDialogshowError = true;
      var coverm = document.querySelector('#coverm');
      coverm.style.display = 'block';
    },
    //获取反馈类型
    async feedbackcategory() {
      try {
        let data = await fetch.api_feedbackcategory({});
        if (data && data.feedback_category_list && data.feedback_category_list[0]) {
          that.feedback_category_list = data.feedback_category_list;

          if (that.piece) {
            that.feedback_category_list.forEach((e) => {
              if (e.symbol) {
                if (e.symbol == 'ask-for-video') {
                  that.opi.category_id = e.id;
                  that.catename = e.name;
                }
              }
            });
            that.piece = false;
          } else {
            //that.opi.category_id默认是1，选择id为1的
            that.opi.category_id = that.feedback_category_list[0].id;
            that.feedback_category_list.forEach((e) => {
              if (e.id == that.opi.category_id) {
                that.catename = e.name;
              }
            });
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    //开关窗口
    openandclo(type, boo) {
      that[type] = boo;
      var coverm = document.querySelector('#coverm');
      if (boo) {
        coverm.style.display = 'block';
      } else {
        coverm.style.display = 'none';
      }
    },
    // 点击绑定邮箱
    clibindmail() {
      if (that.loadCli) return false;
      that.loadCli = true;
      that.api.user
        .api_secritybindEmail(that.resmail)
        .then((data) => {
          var aaa = JSON.parse(localStorage.getItem('user'));
          aaa.email = data.user.email;
          localStorage.setItem('user', JSON.stringify(aaa));
          that.$store.commit('changelogin');
          that.usepubilDialog('绑定邮箱成功');
          that.user.email = data.user.email;
          that.todialog('center');
        })
        .catch((error) => {
          that.usepubilDialogError(error.message, '');
        })
        .finally(() => {
          that.loadCli = false;
        });
    },
    //获取邮箱验证码
    getvarcode() {
      if (that.varCli) return;
      that.varCli = true;
      if (that.$store.state.code.count === '验证码') {
        that.api.user
          .api_emailCode({ email: that.resmail.email })
          .then((data) => {
            that.YS.getCode();
            that.resmail.phrase_id = data.phrase_id;
          })
          .catch((error) => {
            that.usepubilDialogError(error.message, '');
          })
          .finally(() => {
            that.varCli = false;
          });
      } else {
        that.varCli = false;
      }
    },
    //点击充值记录
    async clipayhis() {
      try {
        //取判断标准老的vip时间，在这之前获取用户信息都会记录老vip时间
        if (localStorage.getItem('vtime')) {
          that.vipOldtime = JSON.parse(localStorage.getItem('vtime'));
        } else {
          //获取不到取user里的
          if (localStorage.getItem('user')) {
            that.vipOldtime = JSON.parse(localStorage.getItem('user')).vip_expired_time;
            localStorage.setItem('vtime', JSON.stringify(that.vipOldtime));
          }
        }
        // that.vipOldtime = JSON.parse(localStorage.getItem('user')).vip_expired_time;
        let data = await that.api.user.api_info({});
        var tem_local = JSON.parse(localStorage.getItem('user'));
        tem_local.vip_expired_time = data.user.vip_expired_time;
        localStorage.setItem('user', JSON.stringify(tem_local));
        that.$store.commit('changelogin');
        that.$store.commit('getisvipCode');
        that.getpayhis();
        that.todialog('rec');
      } catch (error) {
        console.log(error.message);
      }
    },
    //点击历史记录
    clihis() {
      that.YS.routetogo({ path: '/mrecord', query: { isrec: false } });
    },
    //点击账户信息
    cliusercent() {
      that.todialog('acc');
    },
    //点击意见反馈
    clifeedback() {
      that.feedbackcategory();
      that.todialog('feedback');
      // that.mbottom = false;
    },
    //展开意见
    downFeedbook() {
      // console.log('展开');
      // var coverm = document.querySelector('#coverm');
      if (!that.feedback_isok) {
        that.feedback_isok = true;
        document.getElementsByClassName('feedbookimg')[0].classList.add('p_90');
        // coverm.style.display = 'block';
      } else {
        that.feedback_isok = false;
        document.getElementsByClassName('feedbookimg')[0].classList.remove('p_90');
        // coverm.style.display = 'none';
      }
    },
    //选择意见
    cliFeedbookList(e) {
      that.opi.category_id = e.target.dataset.id;
      that.feedback_category_list.forEach((e) => {
        if (e.id == that.opi.category_id) {
          that.catename = e.name;
        }
      });
    },
    //点击修改昵称-确认修改
    resnickname() {
      if (that.loadCli) return false;
      that.loadCli = true;
      that.resetUsername();
    },
    //加载长渐变背景样式
    backgroundlong() {
      var mTop = document.getElementById('mTop');
      var mtopBlock = document.getElementById('mtopBlock');
      // mTop.setAttribute('style', 'background:none !important;');
      mTop.setAttribute('style', 'background: linear-gradient(180deg, #a9dbf9 0%, #c4e6fa 100%);');
      mtopBlock.setAttribute('style', 'display:none !important;');
    },
    //加载短渐变背景样式
    backgroundshort() {
      var mTop = document.getElementById('mTop');
      var mtopBlock = document.getElementById('mtopBlock');
      mTop.setAttribute(
        'style',
        'background:linear-gradient(180deg, rgba(169,218,248) 0%, #ffffff 100%) !important;',
      );
      mtopBlock.setAttribute('style', 'display:block !important;');
    },
    // 进入指定页面
    todialog(a) {
      Object.keys(that.mcendg).forEach((e) => {
        that.mcendg[e] = false;
      });
      that.mcendg[a] = true;
      if (a == 'center') {
        that.backgroundlong();
        that.mbottom = true;
      } else {
        that.backgroundshort();
        that.mbottom = false;
      }
    },
    //《我的》点击事件
    cliuser() {
      that.user = JSON.parse(localStorage.getItem('user'));
      that.tokenval = JSON.parse(localStorage.getItem('token'));
      if (
        !that.tokenval ||
        !that.user ||
        (that.tokenval &&
          that.tokenval.expired_time < Date.parse(new Date()).toString().substr(0, 10))
      ) {
        that.YS.routetogo({ path: '/mlogin' });
      } else {
        that.todialog('center');
        // that.YS.routetogo({ path: '/mcenter' });
      }
    },
    //点击退出登录，弹窗
    outloginbefore() {
      that.openandclo('outl', true);
    },
    //退出登录
    outlogin() {
      that.$store.state.islogin = false;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      that.$store.commit('changelogin');
      that.YS.routetogo({ path: '/mhome' });
    },
    //api获取充值记录
    getpayhis() {
      fetch.api_rechargeRecord(that.pages).then((data) => {
        that.recharge_record_list = data.recharge_record_list;
      });
    },
    //api修改昵称
    resetUsername() {
      that.api.user
        .api_changeNickname({ nickname: that.resnickname_input })
        .then((data) => {
          // that.user.nickname = that.resnickname_input;
          that.user.nickname = data.user.nickname;
          // that.$set(that.user, 'nickname', that.resnickname_input);
          localStorage.setItem('user', JSON.stringify(that.user));
          that.$store.commit('changelogin');
          that.usepubilDialog('修改昵称成功');
          that.todialog('acc');
        })
        .catch((error) => {
          that.usepubilDialogError(error.message, '');
        })
        .finally(() => {
          that.loadCli = false;
        });
    },
    // api修改密码
    respow() {
      if (that.loadCli) return false;
      that.loadCli = true;
      that.errorpass = '';
      if (!!that.oldpass && !!that.newpass && that.newpass === that.respass) {
        that.api.user
          .api_changePassword({
            original_password: that.oldpass,
            new_password: that.newpass,
          })
          .then((data) => {
            localStorage.setItem('token', JSON.stringify(data.token));
            that.usepubilDialog('密码修改成功');
            that.todialog('safe');
            that.oldpass = '';
            that.newpass = '';
            that.respass = '';
          })
          .catch((error) => {
            that.errorpass = error.message;
          })
          .finally(() => {
            that.loadCli = false;
          });
      } else if (that.newpass != that.respass) {
        that.loadCli = false;
        that.errorpass = '两次输入的密码不一致，请重新输入';
      }
    },
    // api修改午夜版密码
    resHidden() {
      if (that.loadCli) return false;
      that.loadCli = true;
      that.errorpass = '';
      if (!!that.oldpass && !!that.newpass && that.newpass === that.respass) {
        that.api.user
          .api_secritychangeNightPassword({
            original_password: that.oldpass,
            new_password: that.newpass,
          })
          .then(() => {
            that.usepubilDialog('午夜版密码修改成功');
            that.todialog('safe');
            that.oldpass = '';
            that.newpass = '';
            that.respass = '';
          })
          .catch((error) => {
            that.errorpass = error.message;
          })
          .finally(() => {
            that.loadCli = false;
          });
      } else if (that.newpass != that.respass) {
        that.loadCli = false;
        that.errorpass = '两次输入的密码不一致，请重新输入';
      }
    },
    //获取邮箱验证码
    getmailver() {
      if (that.varCli) return false;
      that.varCli = true;
      that.$store.state.code.count === '验证码' &&
        that.api.user
          .api_emailCode({ email: that.cemail.email })
          .then((data) => {
            that.YS.getCode(that.usepubilDialogError);
            that.cemail.phrase_id = data.phrase_id;
          })
          .catch((error) => {
            that.usepubilDialogError(error.message, '');
          });
      that.varCli = false;
    },

    //通过邮箱重置密码
    cliresmail() {
      that.api.user
        .api_resetPasswordByEmail(that.cemail)
        .then((data) => {
          localStorage.setItem('token', JSON.stringify(data.token));
          localStorage.setItem('user', JSON.stringify(data.user));
          that.$store.commit('changelogin');
          that.usepubilDialog('修改密码成功', '');
          that.todialog('safe');
        })
        .catch((error) => {
          that.usepubilDialogError(error.message, '');
        });
    },
    //修改绑定邮箱
    climailRes() {
      that.api.user.api_secritybindEmail(that.resmail).then((data) => {
        var aaa = JSON.parse(localStorage.getItem('user'));
        aaa.email = data.user.email;
        localStorage.setItem('user', JSON.stringify(aaa));
        that.$store.commit('changelogin');
        that.usepubilDialog('绑定邮箱成功', '');
        that.YS.gotoVip(1, 'close', 'mailReg');
      });
    },
    //重复获取支付结果，重复事件
    getvipres() {
      //取判断标准老的vip时间，在这之前获取用户信息都会记录老vip时间
      if (localStorage.getItem('vtime')) {
        that.vipOldtime = JSON.parse(localStorage.getItem('vtime'));
      } else {
        //获取不到取user里的
        if (localStorage.getItem('user')) {
          that.vipOldtime = JSON.parse(localStorage.getItem('user')).vip_expired_time;
          localStorage.setItem('vtime', JSON.stringify(that.vipOldtime));
        }
      }
      var set2 = setInterval(() => {
        // if (that.$store.state.showlogin.payres == false) {
        //   that.YS.gotoVip({}, 'open', 'payres');
        // }
        if (that.geiviptime === 0) {
          that.api.user.api_info({}).then((data) => {
            var tem_local = JSON.parse(localStorage.getItem('user'));
            tem_local.vip_expired_time = data.user.vip_expired_time;
            localStorage.setItem('user', JSON.stringify(tem_local));
            that.$store.commit('changelogin');
            that.$store.commit('getisvipCode');
            that.geivipcount += 1;
            that.vipNewtime = data.user.vip_expired_time;
            if (that.isvip && that.vipNewtime != that.vipOldtime) {
              clearInterval(set2);
              localStorage.removeItem('pay_ok');
              that.openandclo('loadwin', false);
              that.usepubilDialog('充值成功！', '/mcenter');
              localStorage.setItem('vtime', JSON.stringify(data.user.vip_expired_time));
            }
          });
          that.geiviptime = 10;
        } else if (that.geivipcount > 1) {
          clearInterval(set2);
          that.openandclo('loadwin', false);
          that.openandclo('loadwinSecond', false);
          that.usepubilDialogError(
            '获取结果失败，请刷新重试！如确认支付完成可重新登录查看状态。\n（PAYPAL延迟较大，建议稍后重试，请勿重复付款充值）',
          );
        } else {
          that.geiviptime -= 1;
        }
      }, 1001);
    },
    //写入进入支付成功的时间
    setOrder() {
      // if (that.$route.query.time) {
      //
      // } else {
      //   return;
      // }
      if (!that.jsonList) return;
      // if (that.$route.query.time) {
      if (that.jsonList.pay_time) {
        //获取世界时间戳
        let time = Math.round(
          new Date(decodeURIComponent(that.jsonList.pay_time).replace(/\+/g, ' ')).getTime() / 1000,
        ).toString();
        //获取用户地区与世界的时间差
        let timeCha = Math.abs(new Date().getTimezoneOffset());
        localStorage.setItem('pay_ok', parseInt(time) + parseInt(timeCha) * 60);
      } else if (that.jsonList.time) {
        localStorage.setItem('pay_ok', that.jsonList.time);
      } else {
        return;
      }
    },
    mpubyes() {
      switch (that.pubtype) {
        case '/mcenter':
          //返回用户中心
          that.YS.routetogo({ path: '/mcenter' });
          that.pubtype = '';
          break;

        default:
          break;
      }
      that.openandclo('publicDialogshow', false);
    },
    //公用弹窗，失败点击按钮
    pubErrorbutton() {
      switch (that.pubErrortype) {
        case '/gopay':
          //返回用户中心
          debugger;
          that.YS.routetogo({ path: '/mcenter', query: { go: 'pay' } });
          that.pubErrortype = '';
          break;

        default:
          break;
      }
      that.openandclo('publicDialogshowError', false);
    },
  },
};
</script>
<style lang="scss" scope>
.m_total {
  .lt-input-phoneSgin {
    width: 100%;
  }
  .vue-country-intl .dropdown-flag {
    padding-right: 20px !important;
  }
  .vue-country-intl .country-intl-input {
    height: 23px !important;
    // width: 234px !important;
    width: 24px !important;
    // border: 1px solid #cbcbcb !important;
  }
  .vue-country-intl .country-intl-label {
    height: 23px !important;
    // width: 234px !important;
    width: 58px !important;
    padding: 9px 6px !important;
    display: flex !important;
    align-items: center !important;
  }
  .vue-country-intl .country-intl-label .iti-flag {
    margin-right: 2px !important;
  }

  .list-on-bottom.focused .vue-country-list-wrap {
    width: 310px !important;
    margin-left: -2px !important;
    // border: 1px solid #cbcbcb !important;
  }
  .vue-country-intl .country-intl-input-wrap {
    border: none !important;
  }
  .vue-country-intl {
    border: 1px solid #cbcbcb !important;
    // border-right: none !important;
    border-radius: 6px 0 0 6px !important;
  }
  .vue-country-intl .country-intl-input-wrap,
  .vue-country-intl .dropdown-flag,
  #vue_country_intl-1-input {
    border-radius: 6px 0 0 6px !important;
  }
  .vue-country-list {
    max-width: 100% !important;
    max-height: 180px !important;
    overflow-x: hidden !important;
    margin-right: -2px !important;
  }
  .phoneSgin_input {
    margin-left: -2px !important;
    // border: 1px solid #cbcbcb;
    // border: 1px solid #e8e8e8;
    border: 1px solid #cbcbcb !important;
    // border: none !important;
    width: 230px;
    height: 40px;
    border-radius: 0 6px 6px 0 !important;
    padding-left: 10px;
    letter-spacing: 1px;
    box-shadow: none !important;
  }
  .countryIOS {
    .phoneSgin_input {
      height: 34px !important;
    }
  }
}
</style>
